<template>
  <div class="loanOffer">
    <component :is="template"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwLoanOffer',

  components: {
    'cw-instalment-and-continuous-loan-offer': () => import('@se/pages/steps/InstalmentAndContinuousLoanOffer'),
  },

  data: () => ({
    template: '',
  }),

  computed: {
    ...mapGetters({
      loanType: 'application/getLoanType',
    }),
  },

  created() {
    const type = this.loanType;

    this.setTemplate(type);
  },

  methods: {
    setTemplate(type) {
      if (!type) return;

      const offerTypes = {
        continuousLoan: 'cw-instalment-and-continuous-loan-offer',
        instalmentLoan: 'cw-instalment-and-continuous-loan-offer',
      };

      this.template = offerTypes[type];
    },
  },
};
</script>
